<template>
    <div>
        <SubHeader :title="title" :link="'/mypage/profile'" />
        <div class="">
            <div class="mx-4">
                <h4 class="mt-4">
                    어떤 점이<br>
                    마음에 들지 않으셨어요?
                </h4>
                <ul class="small my-4">
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-body text-opacity-50">탈퇴 이유를 알려주시면, 더 좋은 플랫폼을 만드는데 도움이 됩니다.</small></li>
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-body text-opacity-50">기기변경/재설치의 경우에는 탈퇴하지 마시고 앱 설치 후 해당 계정으로 접속하시면 복원 가능합니다.</small></li>
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-danger">모든 포인트, 활동 내역등이 삭제됩니다. 삭제된 정보는 복구가 불가능 합니다</small></li>
                </ul>
            
                <div class="py-2 round_checkbox" v-for="(item,index) in ['포인트 혜택이 낮아요',' 앱을 이용하기 불편해요',' 원하는 서비스가 없어요']" :key="index">
                    <input type="radio" v-model="reason" :value="item" :id="'reason'+index">
                    <label :for="'reason'+index">
                        <div class="chk_icon me-2"></div>
                        <small class="conditions text-truncate">{{item}}</small>
                    </label>
                </div>
                <div class="py-2 round_checkbox">
                    <input type="radio" v-model="reason" value="other" id="reason_other">
                    <label for="reason_other">
                        <div class="chk_icon me-2"></div>
                        <small class="conditions text-truncate">기타</small>
                    </label>
                </div>
                <textarea class="w-100 border small rounded-2 p-2" rows="3" v-model="reson_other" :disabled="reason != 'other'"></textarea>
            </div>
            
            <div class="mx-4">
                <form class="form-group mt-3">
                    <label class="small text-gray-relative-600">사용중인 비밀번호</label>
                    <input class="form-control thick" type="password" required placeholder="비밀번호" v-model="pw" autocomplete="none">
                </form>
                <div class="ms-2 mt-2 d-inline-block">
                    <span class="small text-gray-relative-600">비밀번호를 잊으셨나요? </span>
                    <router-link to="/find-pw" class="small text-decoration-underline">비밀번호 재설정</router-link>
                </div>
            </div>
            <div class="row mx-3 g-2 mt-5 pb-5">
                <div class="col col-5"><router-link to="/home" class="btn thick bg-gray-relative-100 text-body w-100">취소</router-link></div>
                <div class="col col-7"><button class="btn thick btn-main text-white w-100" :disabled="Chk()" @click="OptOut()">탈퇴하기</button></div>
            </div>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'

export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title:'회원탈퇴',

            reason: '',
            reson_other: '',
            pw: '',
        }
    },
    methods:{
        Chk(){
            let disabled = false;

            if( this.reason != '' ){
                if( this.reason == 'other' && this.reson_other == '' ){
                    disabled = true
                }
            }else{
                disabled = true;
            }

            return disabled;
        },
        OptOut(){
            this.$confirm('탈퇴하시겠습니까?').then(( result )=>{
                if( result.isOk ){
                    this.$alert('정상적으로 탈퇴되었습니다.<br>이용해주셔서 감사합니다').then(()=>{
                        this.$router.push('/home');
                    });
                }
            })
        }
    }
}
</script>

<style>

</style>